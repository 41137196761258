
:root{
  --edgeberry_black: #1e1e1e;
  --edgeberry_blue: #0007ff;
  --edgeberry_darker_blue: #0066CC;
  --edgeberry_dark_blue:#004080;
  --edgeberry_lighter_blue: #66B3FF;
  --edgeberry_dark_gray: #636363;
  --edgeberry_gray: #808080;
  --edgeberry_silver: #c0c0c0;
  --edgeberry_light_gray: #efefef;
  --edgeberry_white: #ffffff;
  --edgeberry_red: #dc143c;
  --edgeberry_green: #00ff00;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--edgeberry_black);
  background-image: url('/public/cardboard.png');
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  text-align: center;
  min-height: 100vh;
  background-image: url('/public/Laika_Guevara.png');
  background-size: 15vw;
  background-repeat: no-repeat;
  background-position: bottom right;
}

.App-dark {
  background-color: var(--edgeberry_black);
}

.App-logo {
  height: 18vmin;
  pointer-events: none;
}

.App-centered {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--edgeberry_silver);
}

.container-fluid{
  padding: 0px;
  min-width: 100%;
  width: 100%;
  text-align: left;
}

.container-page {
  text-align: left;
  min-width: 100%;
  max-width: 100%;
  min-height: calc(100vh - 60px);
  height: calc(100vh - 60px);
  overflow: hidden;
}

/* Sign-in box */
.centerbox{
  background-color: var(--edgeberry_black);
  margin-top: calc(100vh / 7) !important;
  width: 100%;
  max-width: 370px;
  padding: 30px;
  border-radius: 15px;
  margin: auto;
  color: var(--edgeberry_silver);
  box-shadow: rgba(99, 99, 99, 0.5) 0px 2px 8px 0px;
}

/* Buttons */
.btn-primary, .btn-primary:active, .btn-primary:visited {
  background-color: var(--edgeberry_blue);
  border-color: var(--edgeberry_darker_blue);
  box-shadow: 0 0px 0px var(--edgeberry_blue) inset, 0,0,0px var(--edgeberry_darker_blue);
  color: var(--edgeberry_white);
}

.btn-primary:hover {
  background-color: var(--edgeberry_darker_blue);
  border-color: var(--edgeberry_blue);
  box-shadow: 0 0px 0px var(--edgeberry_darker_blue) inset, 0,0,3px var(--edgeberry_blue);

}

/* Danger button */
.btn-danger, .btn-danger:active, .btn-danger:visited {
  background-color: var(--edgeberry_red);
}

/* Invisible button */
.btn-invisible {
  background-color: transparent;
  border: none;
  color: inherit;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
}

.btn-invisible:hover,
.btn-invisible:focus {
  outline: none;
}


.navbar, .navbar-dark {
  background-color: var(--edgeberry_black) !important;
}

/* Asset List Cards */

.asset-cartdeck{
  color: var(--edgeberry_black);
}

.asset-card-container{
  padding: 2px;
}

.asset-card{
  width:100%;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.5) 0px 2px 8px 0px;
}

.asset-card:hover{
  border-color: var(--edgeberry_blue);
  background-color: var(--edgeberry_lighter_blue);
  /* cursor: pointer; */
  box-shadow: rgba(99, 99, 99, 0.5) 0px 2px 8px 0px;
}

.asset-card-menu{
  /*position: absolute;*/
  width: 100%;
  padding: 7px;
  display: inline;
  text-align: right;
}

.asset-card-menu-btn{
  font-size: 1.5rem;
  line-height: 0px;
  font-weight: bold;
  background-color: transparent;
  border-color: var(--edgeberry_gray);
  color: var(--edgeberry_dark_gray);
  padding: 4px;
  margin: 1px;
  min-width: 2.3rem;
}

.asset-card-body{
  background-color: var(--edgeberry_black);
  color: var(--edgeberry_light_gray);
  text-align: left;
}

.asset-card-title{
  font-Size: 1.3rem;
  font-weight:bold;
  color: var(--edgeberry_blue);
  overflow: hidden;
  white-space: nowrap;
}

.asset-card-text{
  color: var(--edgeberry_silver);
  overflow: hidden;
  white-space: nowrap;
}


/* Container on the bottom */
.container-bottom {
  position: absolute;
  bottom:0;
}

/* Individually scrolling container */
.scroll-container{
  padding:0;
  margin: 0;
  height: calc(100vh - 80px);
  overflow-y: scroll;
  -ms-overflow-style: none;   /* Hidden scrollbar: Internet Explorer 10+ */
  scrollbar-width: none;      /* Hidden scrollbar: Firefox */
}
.asset-list-container::-webkit-scrollbar { 
    display: none;            /* Hidden scrollbar: Safari and Chrome */
}

/* Text styling */

.text-subtitle{
  font-size: 1.0rem;
  color: var(--edgeberry_dark_gray);
}